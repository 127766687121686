<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import CopilotPlainSvg from '@/assets/svg/copilot-plain.svg';
import ScheduledUpdatesService from '@/core/jobs/scheduled-updates/scheduled-updates.service';
import {
  JobStatus,
  type ProjectDistributionScheduledRotation,
} from '@factoryfixinc/ats-interfaces';
import DateUtils from '@/utils/date-utils';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { stripHtml } from '@/utils/strings/strip-html.utils';
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import type { JobStatusHistory } from '@factoryfixinc/ats-interfaces/dist/types/job-status-history.model';

const copilotActivationService = new CopilotActivationService();
const scheduledUpdatesService = new ScheduledUpdatesService();

const DAYS_FOR_ROTATION = 14;

const props = defineProps<{
  modelValue: boolean;
  projectId?: number;
  copilotStatus: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
  (e: 'exit'): void;
}>();

const VERSIONS = [
  {
    id: 1,
    name: 'Update 1',
  },
  {
    id: 2,
    name: 'Update 2',
  },
  {
    id: 3,
    name: 'Update 3',
  },
];
const selectedVersionId = ref(1);
const selectedRotation = ref<ProjectDistributionScheduledRotation>();
const currentScheduledUpdate = computed(() => scheduledUpdatesService.currentScheduledUpdate);
const currentRotation = computed(
  () => scheduledUpdatesService.currentScheduledUpdate?.currentRotation,
);

// We need to find when was copilot activated to show the correct Date
const jobStatusHistory = ref<JobStatusHistory[]>([]);
const copilotActivation = computed(() => {
  return [...jobStatusHistory.value].reverse().find((history) => history.status === JobStatus.LIVE);
});

function selectVersion(versionId: number) {
  selectedVersionId.value = versionId;
  if (currentScheduledUpdate.value !== null && currentScheduledUpdate.value.scheduledRotations) {
    selectedRotation.value = getRotationByVersionId(versionId);
  }
  // Adding a small delay to track the selected version
  // just to make sure that "SCHEDULED_CHANGES_MODAL_OPENED" tracking will be send first
  const trackinTimeout = setTimeout(() => {
    trackSelectedVersion(versionId);
    clearTimeout(trackinTimeout);
  }, 300);
}

function trackSelectedVersion(versionId: number) {
  TrackingService.trackAction(TrackingActionName.SCHEDULED_UPDATE_VIEWED, {
    project_id: props.projectId,
    job_id: currentScheduledUpdate.value?.jobId,
    current_post: currentRotation.value ? `Update ${currentRotation.value.version}` : 'Original',
    scheduled_update_viewed: `Update ${versionId}`,
    copilot_status: props.copilotStatus,

    original_title: currentScheduledUpdate.value?.title,
    original_description: stripHtml(currentScheduledUpdate.value?.description ?? ''),
    original_schedule: copilotActivation.value
      ? DateUtils.formatDate(copilotActivation.value.createTs)
      : 'Not available',

    ...getRotationsTrackingData(),
  });
}
/**
 * Need to track all 3 scheduled updates as:
 *  scheduled_update_1_title, scheduled_update_2_title, scheduled_update_3_title, etc.
 *  for Title, Description and Schedule
 */
function getRotationsTrackingData(): { [key: string]: string | Date } {
  const trackingData: { [key: string]: string | Date } = {};
  currentScheduledUpdate.value?.scheduledRotations?.forEach((rotation) => {
    trackingData[`scheduled_update_${rotation.version}_title`] = rotation.title;
    trackingData[`scheduled_update_${rotation.version}_description`] = stripHtml(
      rotation.description,
    );
    trackingData[`scheduled_update_${rotation.version}_schedule`] = stripHtml(
      bannerDateText(rotation.version),
    );
  });
  return trackingData;
}

const jobTitle = computed<string>(() =>
  selectedRotation.value ? selectedRotation.value.title : '',
);

const jobDescription = computed<string>(() =>
  selectedRotation.value ? selectedRotation.value.description : '',
);

const isOpen = computed<boolean>(() => props.modelValue);

function handleUpdateModelValue(value: boolean) {
  selectedVersionId.value = 1;
  selectedRotation.value = undefined;
  emit('update:modelValue', value);
}

function handleExit() {
  emit('exit');
  handleUpdateModelValue(false);
}

function bannerDateText(versionId: number): string {
  const rotation = getRotationByVersionId(versionId);

  if (rotation && rotation.id === currentScheduledUpdate.value?.currentRotation?.id) {
    return `This update is <strong>live</strong>`;
  }
  let newScheduledDate: Date | undefined;

  if (rotation) {
    /**
     * This is done in the frontend since the new dates are calculated until we rotate all the versions
     * and get back to the Original version (in the back end rotaion logic)
     *
     * We need to always show the date Rotation will be posted in the future at 14 days interval even if it was already posted
     * Original version Now -> Update 1 in 14 days -> Update 2 in 28 days -> Update 3 in 42 days -> Original version in 56 days
     * so we need to calculate the new scheduledDate for the selected Rotation if version is less than the currentRotation
     * */
    if (currentRotation.value && rotation?.version < currentRotation.value?.version) {
      // +14 days since we need to account for Original version
      const daysToAdd =
        (rotation.version + VERSIONS.length - currentRotation.value.version) * DAYS_FOR_ROTATION +
        DAYS_FOR_ROTATION;

      newScheduledDate = new Date(currentRotation.value?.scheduledDate);
      newScheduledDate.setDate(newScheduledDate.getDate() + daysToAdd);
    } else {
      newScheduledDate = rotation.scheduledDate;
    }
    // Need to add +1 day since we are calculating the days from now 
    return `Scheduled to be posted in <strong>${DateUtils.calculateDays(newScheduledDate)}</strong>
    (${DateUtils.formatDate(newScheduledDate)})`;
  }

  return 'Scheduled Update not available';
}

function getRotationByVersionId(
  versionId: number,
): ProjectDistributionScheduledRotation | undefined {
  return currentScheduledUpdate.value?.scheduledRotations?.find(
    (rotation) => rotation.version === versionId,
  );
}
// We will set the default version to 1 when we open the modal
watch(isOpen, (isOpen) => {
  if (isOpen) {
    selectVersion(1);

    TrackingService.trackAction(TrackingActionName.SCHEDULED_CHANGES_MODAL_OPENED, {
      project_id: props.projectId,
      job_id: currentScheduledUpdate.value?.jobId,
      copilot_status: props.copilotStatus,
      current_post: currentRotation.value ? `Update ${currentRotation.value.version}` : 'Original',
    });
    if (copilotActivationService.selectedJob) {
      jobStatusHistory.value = copilotActivationService.selectedJob.jobStatusHistories ?? [];
    }
  }
});
</script>
<template>
  <v-dialog :model-value="isOpen" max-width="800px" persistent class="h-screen">
    <div class="flex h-[90vh] flex-col rounded-lg bg-white p-6">
      <div class="modal-header">
        <img
          class="float-right mt-1 cursor-pointer"
          src="@/assets/svg/close-black.svg"
          alt="close"
          width="20"
          height="20"
          @click="handleExit"
        />
        <p class="mb-5 font-serif text-2xl font-black text-copilot">Preview updates</p>
        <div class="tab-header">
          <div
            v-for="version in VERSIONS"
            :key="version.id"
            class="flex cursor-pointer items-center justify-center px-4 py-2.5 text-sm"
            :class="{
              'border-b-2 border-b-shade-880 font-bold text-shade-880':
                selectedVersionId === version.id,
              'text-shade-700': selectedVersionId !== version.id,
            }"
            @click="selectVersion(version.id)"
          >
            {{ version.name }}
          </div>
        </div>
      </div>
      <div class="modal-content">
        <div>
          <div class="banner">
            <div class="white-background">
              <div class="gradient-background">
                <img :src="CopilotPlainSvg" class="mr-2" width="24" height="24" alt="copilot" />
                <span v-html="bannerDateText(selectedVersionId)"></span>
              </div>
            </div>
          </div>
          <div class="job-title">{{ jobTitle }}</div>
          <div v-html="jobDescription" class="prose max-w-full break-words text-sm"></div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<style lang="postcss" scoped>
.tab-header {
  @apply flex justify-start gap-4;
  border-bottom: 1px solid var(--Tint-100, #cbd5e1);
}
.modal-header {
  @apply flex-shrink-0;
}
.modal-content {
  @apply -ml-6 -mr-6 flex-1 overflow-y-auto px-6;
}
.banner {
  @apply mt-6 flex items-center rounded-md p-[1px] text-sm;
  background: linear-gradient(90deg, #d45aff, #ddc6ff 31.5%, #4400ff 48%, #d45aff 100%);

  .white-background {
    @apply h-full w-full rounded-md bg-white;
  }
  .gradient-background {
    @apply flex h-full w-full items-center  rounded-[8px] px-3 py-2;
    background: linear-gradient(
      96deg,
      rgba(212, 90, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 40%,
      rgba(255, 255, 255, 0.08) 60%,
      rgba(68, 0, 255, 0.08) 100%
    );
  }
}
.job-title {
  @apply my-6 text-base font-bold leading-6 text-shade-900;
}
</style>
